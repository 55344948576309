import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
// import NotFound from './views/NotFound.vue'
Vue.use(Router)

const router =  new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta:{
        title: "TaxSlips | Canada's Online Tax Returns"
      }
    },
    //   {
    //
    //   path: '/guides/t4d-guide',
    //   name: 't4guide',
    //   // name:'guide',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: function () {
    //     return import(/* webpackChunkName: "about" */ './views/Guide-T4.vue')
    //     // return import(/* webpackChunkName: "about" */ './views/Guide-T4.vue')
    //   },
    //   meta:{
    //     title: "T4 Guide | TaxSlips"
    //   }
    // },
    {
      // path: '/guides/general-guide',
      // path: '/guides/:guid',
      path: '/guides/:slug',
      name: 'guide',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: function () {
        return import(/* webpackChunkName: "about" */ './views/Guide.vue')
      },
      meta:{
        title: "Guide | TaxSlips"
      }
    },
     // {
     //   path: '/notFound',
     //   name: 'notfound',
     //  component: NotFound
     //
     //
     // },
    // {
    //   path: '/guides/rl1-guide',
    //   name: 'rl1-guide',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: function () {
    //     return import(/* webpackChunkName: "about" */ './views/Rl1-Guide.vue')
    //   },
    //   meta:{
    //     title: "RL1 Guide | TaxSlips"
    //   }
    // },
    // {
    //   path: '/guides/t4a-guide',
    //   name: 't4aguide',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: function () {
    //     return import(/* webpackChunkName: "about" */ './views/Guide-T4A.vue')
    //   },
    //   meta:{
    //     title: "T4A Guide | TaxSlips"
    //   }
    // }
  ]

})

// router.beforeEach((to, from, next) => {
//   if (!to.matched.length) {
//   	next('/notFound');
//   } else {
//   	next();
//   }
// })
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
      // Start the route progress bar.
      // NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  // NProgress.done()
})




export default router
