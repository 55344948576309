  <template>
  <div>
    <section id="contactus">
      <div class="container">
        <h1 class="text-center font-weight-700">Contact Us</h1>
        <p class="text-center gray-text mb-10">Please feel free to contact us using the form below or <br/>by calling <b>(905) 624-9559</b><br/> with your comments, questions, concerns, or to inquire about our products and services.</p>
        <div class="row">
          <div class="col-md-8">
            <b-form @submit="onSubmit" >
              <b-form-group id="exampleInputGroup1"
                            label="Your Email*"
                            label-for="cemail">
                <b-form-input id="cemail"
                              v-model="form.email"
                              placeholder="Enter email">
                </b-form-input>
              </b-form-group>
              <p class="showerror">{{ checkEmail }}</p>
              <b-form-group id="exampleInputGroup2"
                            label="Full Name*"
                            label-for="cname">
                  <b-form-input id="cname"
                                type="text"
                                v-model="form.name"
                                placeholder="Enter name">
                  </b-form-input>
              </b-form-group>
              <p class="showerror">{{ checkName }}</p>
              <b-form-group id="exampleInputGroup3"
                            label="Your Phone"
                            label-for="cphone">
                <b-form-input id="cphone"
                              type="number"
                              v-model="form.phone"
                              placeholder="Enter Phone">
                </b-form-input>
              </b-form-group>
              <b-form-group id="exampleInputGroup4"
                            label="Your Message"
                            label-for="cmsg">
                <b-form-textarea id="cmsg"
                                 v-model="form.msg"
                                 placeholder="Enter something"
                                 :rows="4"
                                 :max-rows="6">
                </b-form-textarea>
              </b-form-group>
              <p v-if="successMsg" class="successmsg">{{ successMsg }}</p>
              <p v-if="errorMsg" class="errormsg">{{ errorMsg }}</p>
              <b-img alt="loading" :src="require('../assets/loading.gif')" class="loading"/>
              <b-button type="submit" class="btn btn-custom-2-f mb-5" :disabled="validated" variant="none">Submit</b-button>

            </b-form>
          </div>
          <div class="col-md-4 bg-white">
            <div class="row mb-5" >
                <div class="col-md-2 text-center"><span class="ti-pin f-30 icon-gradient" ></span></div>
                <div class="col-md-10"><b>Hatchbox Inc. <br/>1550 South Gateway Rd., Suite 229 Mississauga, ON L4W 5G6</b></div>
            </div>
          <div class="row mb-5" >
            <div class="col-md-2 text-center"><span class="ti-mobile f-30 icon-gradient" ></span></div>
            <div class="col-md-10"><b>(905) 624-9559</b></div>
          </div>
          <div class="row mb-3" >
            <div class="col-md-2 text-center"><span class="ti-email f-30 icon-gradient" ></span></div>
            <div class="col-md-10"><b><a href="mailto:hello@taxslips.ca">hello@taxslips.ca</a></b></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

  <script>
  export default {
  data () {
    return {
      form: {
        email: '',
        name: '',
        phone: '',
        successMsg: '',
        errorMsg: ''
      },
      haserror: false,
      apiLink: null,
      successMsg: '',
      errorMsg: '',
      checkEmail : '',
      checkName : '',
      validated : false
    }
  },
  methods: {
    onSubmit (evt) {
    evt.preventDefault();
    this.successMsg = "";
    this.errorMsg = "";
    if (!this.form.name) {
      this.checkName = "Name Required*"
    }
    else{
      this.checkName = ""
    }
    if (!this.form.email) {
      this.checkEmail = "Email Required*"
    } else
    if (!this.validEmail(this.form.email)) {
     this.checkEmail = "Invalid Email"
    }else{
     this.checkEmail = "";
    }
    if(this.form.name && this.form.email){
      this.validated = true;
var staticUrl = "https://api.taxslips.ca/";
      var parametersApi = "api/taxslips/send/mail/";
      $('.loading').css("opacity","1");
      this.apiLink = staticUrl + parametersApi;
      var postData = {
        AppKey : "b8db2c3d3f3eeea8ba4cff746dc9b23258d05m09y1991",
        FromEmail : this.form.email,
        Name: this.form.name,
        PhoneNo: this.form.phone,
        Message: this.form.msg
      };
    var vr = this;
    axios.post(this.apiLink, postData)
    .then(function (response) {
    console.log(response.data.message);
      if(response.data.status)
      {
        $('.loading').css("opacity","0");
        vr.successMsg = response.data.message;
         vr.validated = false;
      }
      else{
        vr.errorMsg = response.data.message;
      }
    })
    .catch(function (error) {
    console.log("Eror: " + error.response);
    vr.errorMsg = "Something Went Wrong! Try Again!";
    });

    } // if condition ends
  },
  validEmail: function (email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  }
  };
  </script>
  <style lang="scss">
  #contactus .form-control {
  min-height: 42px;
  font-size: 12px;
  border: none;
  border-radius: 20px;
  padding: 12px 30px;
  box-shadow: 0 0 5px 0 rgba(170, 170, 170, 0.15);
  }
  #contactus .col-form-label, #contactus small{
  padding-left:19px;
  }

  #contactus  .hascerror {

  border: 1px solid #ff0000 !important;
  }
  .hasemptyemail,.hasinvalemail,.hasemptyname,.sucessmsg{
  display:none;
  }
  .showerror{
  font-size: 13px;
  color: #ff0000;
  padding-left: 19px;
  margin-top: -9px;
  }
  .showSuccess{
  font-size: 13px;
  font-weight:bold;
  color: #70b728;
  padding-left: 19px;
  margin-top: -9px;
  }
  .successmsg{
    color: green;
  }
  .errormsg{
    color:red;
  }
  .loading{
    display:block;
    opacity: 0;
  }
  </style>
