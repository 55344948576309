import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'





Vue.use(Vuex)

export default new Vuex.Store({
  state:{
      taxslipCMS:[],
      // generalGuide:[],




  },
  actions:{
        loadTaxslipCMS({commit}){
          // NProgress.start()
          axios.get('https://app.taxslips.ca/taxslip_api/home_api/list/',{"params": {token: this.token}})
          // axios.get('http://192.168.1.14:8007/taxslip_api/home_api/list/',{"params": {token: this.token}})
          // axios.get('http://stagingapp.taxslips.ca/taxslip_api/home_api/list/',{"params": {token: this.token}})
           .then(response => {
              // NProgress.done()
             // console.log(response.data)
             let cms_data =response.data
             commit('SET_DATA', cms_data)
            })
           .catch(error =>{
             console.log(error)
           })
        },

        // loadGuide({commit}){
        //
        //   axios.get(this.BASE_URL'taxslip_api/api_guide/list',{"params": {token: this.token, flag_id:this.flag}})
        //   // axios.get('http://stagingapp.taxslips.ca/taxslip_api/home_api/list/',{"params": {token: this.token}})
        //    .then(response => {
        //      console.log(response.data)
        //      let guide_data =response.data
        //      commit('SET_GUIDE', guide_data)
        //     })
        //    .catch(error =>{
        //      console.log(error)
        //    })
        // },


  },
  mutations:{
    SET_DATA(state, cms_data){
      state.taxslipCMS = cms_data
    },
    // SET_GUIDE(state, guide_data){
    //   state.generalGuide =guide_data
    // }

  },


})
