<template>
  <div id="home">

    <b-navbar toggleable="lg" type="light" class="fixed-top">
     <div class="container">
       <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
       <b-navbar-brand href="#"  v-for="home in taxslipCMS.data.home">
         <b-img alt="Vue logo" :src="home.logo" class="white-logo"/>
         <b-img alt="Vue logo"  :src="home.logo" class="scroll-logo"/>

      </b-navbar-brand>
      <b-collapse is-nav id="nav_collapse"  >
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item  class="h-link" href="#home">Home</b-nav-item>
          <b-nav-item class="h-link" href="#aboutus">About</b-nav-item>
          <b-nav-item class="h-link" href="#features">Features</b-nav-item>
          <b-nav-item class="h-link" href="#pricing">Pricing</b-nav-item>
          <b-nav-item class="h-link" target="_blank" href="https://help.taxslips.ca/">Support</b-nav-item>
          <b-nav-item class="h-link" href="#contactus">Contact</b-nav-item>
          <b-nav-item  class="btn btn-custom" :href="base_url+'#/accounts/Login'" target="_self">Login</b-nav-item>



          <b-nav-item  class="btn btn-custom-2" :href="base_url+'#/accounts/Registration/'" target="_self">SignUp</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>

  <!-- navbar-1.vue -->

</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'mainheader',
  data () {
return {

  base_url:"https://app.taxslips.ca/",

 home:{

     logo:'',
   },
}

},

  mounted(){
    $(document).ready(function(){
      $(window).scroll(function(){
        var scroll = $(window).scrollTop();
        if (scroll > 100) {
          $(".navbar").css({"background" : "#fff", "box-shadow" : "2px 5px 19px 1px rgba(0,0,0,0.05)", "padding-top" : "0px"});
          $(".navbar-nav").addClass("scroll-nav");
          $(".scroll-logo").css("display","block");
          $(".white-logo").css("display","none");
           $('.return-to-top').fadeIn(200);
        }

        else{
         $(".navbar").css("padding-top","8px");
         $(".navbar-nav").removeClass("scroll-nav");
         $(".white-logo").css("display","block");
         $(".scroll-logo").css("display","none");
          $('.return-to-top').fadeOut(200);
       }
     })
      // Href Scrolling
      $("a[href^='#']").click(function(e) {
        e.preventDefault();

        var position = $($(this).attr("href")).offset().top;
        var position = position - 95;
        $("body, html").animate({
          scrollTop: position
        } ,1000 );
      });

      //Back to top Function\
      $('.return-to-top').click(function() {      // When arrow is clicked
        $('body,html').animate({
            scrollTop : 0                       // Scroll to top of body
        }, 500);
      });

    });
  },

  computed: mapState([
  'taxslipCMS'
]),

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
