import Vue from 'vue'
import App from './App.vue'
import router from './router'
/* Importing Bootstrap Vue Files*/
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import  Typed from 'typed.js'
/* Importing Jquery*/
import Jquery from 'jquery'
global.$ = Jquery;
/* Importing Google Maps */
import * as VueGoogleMaps from "vue2-google-maps";
import store  from './store/store'
import Affix from 'vue-affix';
// import { SpinnerPlugin } from 'bootstrap-vue'
// Vue.use(SpinnerPlugin)
import Vue2Filters from 'vue2-filters'
import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from 'vue-loaders';

Vue.use(VueLoaders);

Vue.prototype.token = "A04AF3075AD2FF681C12538BE8927E82";
// Vue.prototype.BASE_URL = "http://192.168.1.12:8007/";
// Vue.prototype.BASE_URL = "http://stagingapp.taxslips.ca/";
Vue.prototype.BASE_URL = "https://app.taxslips.ca/"

Vue.prototype.flag=1
Vue.use(Vue2Filters)
Vue.use(Affix);
/* For Routing */
router.beforeEach((to,from,next) =>{
  document.title = to.meta.title
  next()
});

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB4XICMkmwCPkDqs79GloMjxXtKR2U0YPo",
    libraries: "places" // necessary for places input
  }
});
/* Calling the Vue Method to load it in project*/
Vue.use(BootstrapVue);

/* Vue Instances */
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
