<template>
  <div class="bg-img">
    <div class="container">
      <div class="row" v-for="home in taxslipCMS.data.home">
      <div class="col-md-5">
        <h1 class="heading-mt-10"  v-model="home.title">{{home.title}}</h1>
        <a  class="btn  btn-custom-2-f jumbo-btn" variant="none" :href="base_url+'#/accounts/Registration/'" target="_blank">Get Started</a>
      </div>
      <div class="col-md-7">
        <b-img :src="home.image" alt="img" class="img-jumbo img-fluid"/>
      </div>

    </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'jumbotron',
  data () {
return {
  base_url:"https://app.taxslips.ca/",
 home:{
     title:'',
     image:'',
   },
}
},
// mounted () {
//
//  this.$store.dispatch('loadTaxslipCMS')
// },

computed: mapState([
'taxslipCMS'
]),


};
</script>

<style lang="scss">
.bg-img{
/*background: #ace0f9;  */
/* background-image: linear-gradient(to top, #F4FAFE 0%, #ace0f9 100%); */
/* background-image: linear-gradient(to right bottom, #63A446, #3861ab); */
// background-image: linear-gradient(to top right, #3861ac, #70b728);
// -webkit-background-image: linear-gradient(to top right, #3861ac, #70b728);
// -webkit-background-image: -webkit-linear-gradient(to top right, #3861ac, #70b728);




    }
    .border-color{
  border: 2px solid #fff !important;
  font-weight: bold;
    }
</style>
