<template>

  <div class="home">

  <a href="javascript:" class="return-to-top"><span class="ti-angle-up"></span></a>
    <main-header/>
    <jumbotron/>
    <!-- About Us -->
    <section  id="aboutus" v-for="about in taxslipCMS.data.about">
    	<div class="container">
    	<h1 class="font-weight-700  text-center"v-model="about.title">{{about.title}} </h1>
      <p class="gray-text text-center pb-2"v-model="about.tag_line"> {{about.title_tagline}} </h1>
    	<div class="row pt-4">
    		<div class="col-md-6">

          <p><span v-html="about.description"></span></p>
          <!-- <p style="white-space: pre-line; word-wrap: break-word" v-model="about.description">
          {{about.description}}
            <a href="https://www.simplepay.ca/" target="_blank"><b>SimplePay.</b></a>
          </p> -->
    			<!-- <p class="pt-1">TaxSlips is cloud based Canadian software which takes the stress out of filing your TaxSlips.</p>
    			<p>It offers T4, T4A and RL-1, both electronic and paper filing.</p>
    			<p>One click to adjust/unadjust slips individually/bulk.</p>
    			<p>It records complete change log of data with timestamp.</p>
    			<p>It gives security features like 2 step authentication and sensitive data masking.</p>
    			<p>TaxSlips integrates with market leading Payroll software <span class="text-orange"><a href="https://www.simplepay.ca/" target="_blank"><b>SimplePay</b></a></span>.</p>
    			<p>It gives you data import options in XML and CSV formats.</p> -->
    		</div>
    		<div class="col-md-6">
    			<b-img alt="Vue logo" :src="about.image" class="img-fluid"/>
    		</div>
    	</div>
    </div>
    </section>
    <!-- Section 1 Starts  -->
    <section class=" text-center features-2" id="features">
    	<div class="container">
      <h1 class="font-weight-700" v-for ="setting in taxslipCMS.data.settings" v-if="setting.flag_id == 1 && setting.settings == 'feature_title' " v-html="setting.setting_label" ></h1>
      <p class="gray-text" v-for ="setting in taxslipCMS.data.settings" v-if="setting.flag_id == 1 && setting.settings == 'feature_tagline' " v-html="setting.setting_label"></p>
      <div class="row features">
        <div class="col-md-3"v-for="feature in taxslipCMS.data.feature">
          <div class="content">

              <span><img alt="Vue logo" :src="feature.image" class="img-fluid" /></span>
            <h6 class="font-weight-600 mt-4" v-model="feature.title">{{feature.title}}</h6 >
            <p class="gray-text" v-model="feature.tag_line">{{feature.title_tagline}}</p>
          </div>
        </div>
      </div>
      </div>
    </section>

    <!-- Pricing Table Starts -->
    <section class=" pricing-table" id="pricing">

    	<div class="container plr-0">
            <h1 class="text-center font-weight-700" v-for ="setting in taxslipCMS.data.settings" v-if="setting.flag_id == 2 && setting.settings == 'price_title' " v-html="setting.setting_label"></h1>
            <p class="text-center" style="color:white;" v-for ="setting in taxslipCMS.data.settings" v-if="setting.flag_id == 2 && setting.settings == 'price_tag_line' " v-html="setting.setting_label"></p>
            <div class="row pricing-wrapper">
            	<div class="col-md-4" v-for="price_info in taxslipCMS.data.price_info">
                <!-- <div class="col-md-4"> -->
            		<div  class="price-wrapper" >
            			<h2 class="text-center pb-2"v-model="price_info.title">{{price_info.title}}</h2 >
            			<hr/>
            			<p class="text-center pb-2">
            				<sup class="price-currency">$</sup><span class="price-value" v-model="price_info.sub_title">{{price_info.sub_title}}</span><br/><span class="text-center small-font" v-model="price_info.sub_title_tagline">{{price_info.sub_title_tagline}}</span>
            			</p>
                      <!-- {{price_info.price_plan}} -->
                  <div class = "row text-center">
                    <div class="col-md-6 col-sm-6 col-lg-6 col-xs-6">
                      <p class="price-heading">No. of Slips</p>
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6 col-xs-6 col-sm-6 col-lg-6 col-xs-6">
                      <p class="price-heading">Charges per slip</p>
                    </div>
                  </div>

                  <div v-for ="price_plan in price_info.price_plan">
                    <hr />

                  <div class = "row text-center" >
                    <div class="col-md-6 col-sm-6 col-lg-6 col-xs-6">
                      <p class="text-center small-font" v-model="price_info.price_plan.lower_slab" v-if="price_plan.upper_slab ==null " >{{price_plan.lower_slab}}{{price_plan.upper_slab}} Above Slips </p>
                      <p class="text-center small-font" v-model="price_info.price_plan.lower_slab" v-else >  {{price_plan.lower_slab}}-{{price_plan.upper_slab}} Slips </p>

                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6 col-xs-6">
                      <p class="main-price" v-model="price_info.price_plan.charges">{{price_plan.charges | currency}}</p>
                    </div>
                  </div>
                    </div>


            		</div>
            	</div>



            </div>
    </div>
    </section>
    <!-- Pricing Table Ends -->
    <!-- Contact Us Starts -->
    <contact-form></contact-form>
    <google-maps/>
    <main-footer/>





  </div>
</template>

<script>

// @ is an alias to /src
import { mapState } from 'vuex'
import mainHeader from '@/components/Header.vue'
import mainFooter from '@/components/Footer.vue'

import jumbotron from '@/components/Jumbotron.vue'
import contactForm from '@/components/ContactForm.vue'
import GoogleMaps from '@/components/GoogleMaps.vue'

export default {
  name: 'home',
  data () {
return {
 about:{

     title:'',
     tag_line:'',
     description:'',
     image:''
   },

   feature:{
       title:'',
       tag_line:'',
       image:''
     },


     price_info:{
         title:'',
         sub_title:'',
         sub_title_tagline:'',
         price_plan:{
           lower_slab:0,
           upper_slab:0,
           charges:0.00,
         },


       },
}

},

  components: {
    mainHeader,jumbotron,contactForm,GoogleMaps,mainFooter
  },
//   mounted () {
//
//  this.$store.dispatch('loadTaxslipCMS')
//
//
// },
computed: mapState([
'taxslipCMS'
]),

  };

</script>
<style>
/***********************
*	Scroll back to top
************************/
.return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.7);
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 999;
}
.return-to-top span {
    color: #fff;
    margin: 0;
    position: relative;
    left: 16px;
    top: 13px;
    font-size: 19px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.return-to-top:hover {
    background: rgba(0, 0, 0, 0.9);
}
.return-to-top:hover span {
    color: #fff;
    top: 5px;
}</style>
