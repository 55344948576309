<template>
  <div id="app">
    <div v-if="isShowing">

      <vue-loaders-ball-spin-fade-loader color="rgb(73, 77, 85)" scale="1" class="spinner"></vue-loaders-ball-spin-fade-loader>
    </div>


<!-- <vue-loaders name="ball-beat" color="red" scale="1"></vue-loaders> -->

    <!-- <pulse-loader :loading="loading" :color="color" :size="size" ></pulse-loader> -->

    <router-view />
    <!-- <vue-progress-bar></vue-progress-bar> -->
  </div>
</template>


<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import VueInstantLoadingSpinner from '@/components/VueInstantLoadingSpinner.vue'
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    VueInstantLoadingSpinner,
      PulseLoader
    },
  data(){
    return {
    isShowing:true,
    loading: true,
     color: "#2c3e50",
     size: "10px"
    }
  },
  created() {
          },
    mounted () {
      // this.$refs.Spinner.hide();
      // NProgress.start();
      // this.$Progress.finish()

      this.$store.dispatch('loadTaxslipCMS');
      setTimeout(() => this.isShowing = false, 1000)
      // if ()
    },
    beforeDestroy () {
      this.isShowing=false;

    }
// created(){
//
//   this.$Progress.start()
//     //  hook the progress bar to start before we move router-view
//     this.$router.beforeEach((to, from, next) => {
//       //  does the page we want to go to have a meta.progress object
//       if (to.meta.progress !== undefined) {
//         let meta = to.meta.progress
//         // parse meta tags
//         this.$Progress.parseMeta(meta)
//       }
//       //  start the progress bar
//       this.$Progress.start()
//       //  continue to next page
//       next()
//     })
//     //  hook the progress bar to finish after we've finished moving router-view
//     this.$router.afterEach((to, from) => {
//       //  finish the progress bar
//       this.$Progress.finish()
//     })
// }

}
</script>

<style lang="scss">
.spinner{
  margin-left:45%;

  z-index: 9999999;
    margin-top: 100px;
  // padding-top:10%;
}
.ball-spin-fade-loader {
    position: absolute !important;
  }
</style>
